html {
  height: 100vh;
}
h5 {
  margin: 0;
}

.loginPage {
  text-align: center;
  min-height: 100vh;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  font-weight: 100;
}

.logo-title-login {
  display: flex;
  flex-direction: column;
  margin: 20% auto;
  align-items: center;

  font-size: 5rem;
  font-weight: 100;
  font-family: "Major Mono Display";
  justify-self: flex-start;
  color: #0ae589;
}

.logo-title-login span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: flex-start;
  color: #0ae589;
  font-family: "Major Mono Display";
}

.logo-title-icon-login {
  font-size: 5rem;
}

.login-button {
  border-radius: 10px;
  color: #0ae589;
  background-color: transparent;
  margin-top: 2rem;
  border: 2px solid #0ae589;
  font-family: "Helvetica Neue";
  font-size: 2rem;
  transition: all 0.3s;
  font-weight: 300 !important;
}

.login-button:hover {
  border-radius: 10px;
  transition: all 0.3s;
  background-color: #0ae58a61;
  margin-top: 2rem;
  border: 2px solid #0ae589;
  font-family: "Helvetica Neue";
  font-size: 2rem;
  font-weight: 300 !important;
}

.logout-button {
  border-radius: 10px;
  color: #0ae589;
  background-color: transparent;
  border: 1px solid #0ae589;
  font-family: "Helvetica Neue";
  font-size: 1rem;
  transition: all 0.3s;
  font-weight: 300 !important;
  position: absolute;
  right: 1rem;
}

.logout-button:hover {
  border-radius: 10px;
  transition: all 0.3s;
  background-color: #0ae58a61;
  border: 1px solid #0ae589;
  font-family: "Helvetica Neue";
  font-size: 1rem;
  font-weight: 300 !important;
}

.Main {
  text-align: center;
  min-height: 100vh;
  background-color: #282c34;
  display: grid;
  grid-template-rows: 100px auto;
  grid-template-areas:
    "header"
    "body-content";
}

.body-content {
  grid-area: body-content;
  display: grid;
  color: white;
  height: 100%;
  max-height: 100%;
}

.available-users-section {
  grid-area: available-users-section;
  padding: 0.5rem;
  background: #21242b;
  scrollbar-width: none;
}

.avatar-grid {
  scrollbar-width: none;
}

.available-users-section::-webkit-scrollbar {
  display: none !important;
}

.avatar-grid::-webkit-scrollbar {
  display: none !important;
}

header {
  display: flex;
  flex-direction: row;
  grid-area: header;
  padding: 1rem;
  align-items: center;
  justify-content: space-around;
  color: white;
  background: #22242c;
  border-bottom: #282c34 solid 1px;
}
/* if the screen is less than 700px  */
@media only screen and (max-width: 870px) {
  .body-content {
    grid-template-rows: 100px auto;
    grid-template-areas:
      "available-users-section"
      "chat-section";
  }
  .available-users-section .avatar-grid {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .available-users-section {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .availableChatUser:focus {
    background: none;
    outline: none !important;
  }
  .logo-title-text {
    font-size: large;
  }

  .logo-title-text-login {
  }

  .logo-title-icon {
    font-size: x-large;
  }

  .logo-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: flex-start;
    color: #0ae589;
  }

  .welcome-text {
    font-size: x-small;
  }

  .avatar-text {
    font-size: small;
  }

  .available-users-section p {
    font-size: small;
  }
  .Main {
    grid-template-rows: 50px auto;
  }
}

/* If the screen is between 700px and 120 px */
@media only screen and (min-width: 870px) and (max-width: 1200px) {
  .available-users-section .avatar-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, 100px);
    overflow-y: scroll;
  }
  .available-users-section {
    overflow-y: scroll;
    height: 100%;
  }
  .availableChatUser:focus {
    background: rgba(3.5%, 82.7%, 67.5%, 0.5);
  }
  .body-content {
    grid-template-columns: 2fr 4fr;
    grid-template-areas: "available-users-section chat-section";
  }

  .logo-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: flex-start;
    color: #0ae589;
  }
  .logo-title-icon {
    font-size: xx-large;
  }
  .available-users-section p {
    font-size: large;
  }
}

/* If the screen is 1200px or wider */
@media (min-width: 1200px) {
  .available-users-section .avatar-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, 100px);
    overflow-y: scroll;

    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .availableChatUser:focus {
    background: rgba(3.5%, 82.7%, 67.5%, 0.5);
  }
  .body-content {
    grid-template-columns: 2fr 4fr;
    grid-template-areas: "available-users-section chat-section";
    height: 100%;
  }
  .available-users-section {
    overflow-y: scroll;
  }
  .available-users-section {
    overflow-y: scroll;
    height: 100%;
  }
  .logo-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: flex-start;
    color: #0ae589;
  }
  .logo-title-icon {
    font-size: xx-large;
  }
  .available-users-section p {
    font-size: large;
  }
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  grid-area: header;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #0ae589;
}

.availableChatUser {
  position: relative;
  transition: 0.3s;
  margin-right: 1rem;
  opacity: 0.5;
}

.user-active {
  opacity: 1;
  color: #0ae589;
  font-weight: 500;
}

.availableChatUser:hover {
  transform: scale(1.2);
  cursor: pointer;
  transition: 0.15s;
  opacity: 1;
}

.unread-user::after {
  content: "";
  width: 20px;
  position: absolute;
  top: 2px;
  right: 2px;
  height: 20px;
  border-radius: 10px;
  background: turquoise;
}

.submit-chat-button {
  border-radius: 10px;
  color: white;
  background: #0ae589;
  border: none;
}

.submit-chat-input {
  border-radius: 10px;
  background: #21242b;
  padding: 0.5rem;
  color: #0ae589;
  border: none;
}

.submit-chat-input:focus {
  outline-style: none;
  box-shadow: 0 0 3px 2px #0ae589 !important;
}

.chat-section {
  grid-area: chat-section;
  padding: 1rem;
  display: grid;
  height: 100%; /* this should be 100% of the parent component, which is body-content*/
  max-height: 70vh;
  max-height: -webkit-calc(50% - 20px);
  max-height: -moz-calc(100vh - 100px);
  max-height: calc(100vh - 100px); /* this should be 100% of the parent component, which is .chat-section */
  grid-template-rows: 50px auto 50px;
}

.chat-section::-webkit-scrollbar {
  display: none !important;
}
.message-board::-webkit-scrollbar {
  display: none !important;
}

.message-board {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  height: 100%;
  scrollbar-width: none;
}

.logo-title-text {
  font-family: "Major Mono Display";
}

.actively-typing-indicator-hidden {
  color: #0ae589;
  font-size: 2rem;
  opacity: 0;
  align-self: flex-end;
}
.actively-typing-indicator-present {
  color: #0ae589;
  font-size: 2rem;
  opacity: 0.5;
  transition: opacity 0.3s;
  align-self: flex-end;
}
